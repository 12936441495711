import '@rainbow-me/rainbowkit/styles.css'

import { connectorsForWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { metaMaskWallet, rabbyWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type { FunctionComponent, ReactNode } from 'react'
import React from 'react'
import { createConfig, http, WagmiProvider } from 'wagmi'

import { internetMoneyMobile } from './internetMoneyMobile'
import wagmiChains from './wagmiChains'

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Popular Wallets',
      wallets: [
        metaMaskWallet,
        rabbyWallet,
        walletConnectWallet,
        internetMoneyMobile({ projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT, walletConnectParameters: {} }),
      ],
    },
  ],
  {
    appName: 'RainbowKit demo',
    projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT,
  },
)

const modifiedChains = wagmiChains.map((chain) => {
  if (chain.id === 943 || chain.id === 369) {
    return {
      ...chain,
      iconBackground: '#ffffff',
      iconUrl: 'https://0xcoast.com/wp-content/uploads/2022/12/pulsechain-ecosystem-02.png',
    }
  }
  return chain
})

const config = createConfig({
  connectors: connectors,
  transports: {
    [process.env.NEXT_PUBLIC_PULSECHAIN]: http(process.env.NEXT_PUBLIC_PLS_RPC),
    [process.env.NEXT_PUBLIC_ETH_CHAIN]: http(
      `https://eth-mainnet.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_KEY}`,
    ), // Ethereum Mainnet (Alchemy),
  },
  chains: modifiedChains,
})

type Props = {
  children: ReactNode
}

const RainbowKit: FunctionComponent<Props> = ({ children }) => {
  const queryClient = new QueryClient()

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>{children}</RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  )
}

export default RainbowKit
